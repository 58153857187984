const tim = {
  title: 'Mais benefícios do seu TIM Black',
  items: [
    {
      sva: 'Deezer Premium',
      description: 'Aplicativo com milhões de músicas para ouvir, sem descontar do pacote de internet.',
      icon: 'tim-deezer',
      extension: 'png'
    },
    {
      sva: 'BANCAH PREMIUM + JORNAIS',
      description: 'As principais revistas do Brasil direto no seu celular, sem custo adicional.',
      icon: 'tim-banca-virtual',
    },
    {
      sva: 'Aya Books',
      description: `Aplicativo de audiolivros que possibilita ouvir em qualquer lugar,
       otimizando tempo e multiplicando conhecimento. Todo mês é disponibilizado um ou
       dois audiolivros de sucesso, de acordo com a oferta.`,
      icon: 'aya',
    },
    {
      sva: 'EXA SEGURANÇA',
      description: 'Soluções digitais para segurança dos smartphones.',
      icon: 'exa-seguranca',
    },
    {
      sva: 'INTERNET ACUMULADA',
      description: 'A internet que você não usar em um mês acumula para o mês seguinte.',
      icon: 'internet-acumulada',
    },
    {
      sva: 'Roaming Nacional',
      description: 'Receba chamadas quando estiver em outro estado sem custo adicional.',
      icon: 'roaming',
    },
    {
      sva: 'SMS',
      description: 'Envie SMS para qualquer operadora do Brasil.',
      icon: 'sms',
    },
  ],
};

export default tim;
